import React from "react";
import "../components/layout.css";

import { navigate } from "gatsby";

const IndexPage = () => {
  React.useEffect(() => {
    navigate("/integrate");
  }, []);
  return null;
};

export default IndexPage;
